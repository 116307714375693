<template>
  <div>
    <div class="row table-utilities mx-0">
      <div class="row ml-2 w-100">
        <div class="col-md-10 search-section">
          <div class="input-group">
            <div class="input-group-prepend bg-white">
              <span class="input-group-text" id="basic-addon1">
                <div class="font-12">
                  <i class="ri-search-line ri-lg ri-sub"></i>
                </div>
              </span>
            </div>
            <input
              type="text"
              class="form-control search-input"
              :placeholder="$t('liquidation.search')"
              @input="setPage"
              v-model="filterInput"
            />
          </div>
        </div>
        <div class="col-md-2 pl-0">
          <button
            class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
            @click="handleExport"
            :disabled="isLoading"
          >
            <div class="font-12">
              <div
                v-if="isLoading"
                class="spinner-border spinner-border-sm text-success mr-1"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
            </div>
            {{ $t("global.export") }}
          </button>
        </div>
      </div>
      <div class="row w-100 ml-2 mt-2">
        <div class="col-md-2 pl-0">
          <b-button
            class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
            id="popover-button-likuidasi"
            href="#"
            tabindex="0"
          >
            <div class="font-12">
              <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
            </div>
            Filter Data
          </b-button>
        </div>
        <b-popover
          placement="right"
          target="popover-button-likuidasi"
          variant="danger"
          triggers="focus"
        >
          <b-tabs content-class="mt-2">
            <b-tab title="Tanggal" active>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-today"
                  :value="filterDateTypes[0]"
                  @change="resetFilter1"
                  checked
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-today">
                  <div class="col">
                    <div class="row ssp-14">Today</div>
                    <div class="row">{{ today | formatDate2 }}</div>
                  </div>
                </label>
              </div>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-yesterday"
                  :value="filterDateTypes[1]"
                  @change="resetFilter1"
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-yesterday">
                  <div class="col">
                    <div class="row ssp-14">Yesterday</div>
                    <div class="row">{{ yesterday | formatDate2 }}</div>
                  </div>
                </label>
              </div>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-week"
                  :value="filterDateTypes[2]"
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-week">
                  <div class="col">
                    <div class="row ssp-14">This week</div>
                    <div class="row">
                      <span>{{ `${getDay(firstDayWeek)}&nbsp;-&nbsp;` }}</span>
                      <span>{{ lastDayWeek | formatDate2 }}</span>
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-month"
                  :value="filterDateTypes[3]"
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-month">
                  <div class="col">
                    <div class="row ssp-14">This month</div>
                    <div class="row">
                      <span>{{ `${getDay(firstDayMonth)}&nbsp;-&nbsp;` }}</span>
                      <span>{{ lastDayMonth | formatDate2 }}</span>
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-check mb-2">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-custom"
                  :value="filterDateTypes[4]"
                  v-model="filterDateType"
                />
                <label class="form-check-label ssp-14" for="radio-custom">
                  Custom Range
                </label>
              </div>
              <b-row v-if="filterDateType == filterDateTypes[4]" class="mb-2">
                <b-col cols="6" class="pr-1">
                  <span>FROM</span>
                  <input
                    type="date"
                    class="form-control float-right"
                    v-model="filterSelected1"
                  />
                </b-col>
                <b-col cols="6" class="pl-1">
                  <span>TO</span>
                  <input
                    type="date"
                    class="form-control"
                    v-model="filterSelected2"
                  />
                </b-col>
              </b-row>
              <button
                @click="resetFilter"
                type="string"
                class="btn btn-success w-100 ssp-14"
                :class="{ disabled: filterDateType === null }"
              >
                {{ $t("filter.reset") }}
              </button>
            </b-tab>
            <b-tab title="Nama SP">
              <div class="input-group">
                <div class="input-group-prepend bg-white">
                  <span class="input-group-text" id="basic-addon1">
                    <div class="font-12">
                      <i class="ri-search-line ri-lg ri-sub"></i>
                    </div>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control search-input"
                  v-model="searchSP"
                />
              </div>
              <hr class="m-2" />
              <div class="filter-overflow-srch">
                <div class="form-checkbox" v-for="sp in dataSP" :key="sp.id">
                  <input
                    class="form-checkbox-item"
                    type="checkbox"
                    name="kegiatan-label"
                    :value="sp.id"
                    :id="sp.id"
                    v-model="filterSP"
                  />
                  <label class="form-checkbox-label ssp-14-400" :for="sp.id">
                    {{ sp.name }}
                  </label>
                </div>
              </div>
              <button
                @click="resetFilter"
                type="string"
                class="btn btn-success w-100 mt-2 ssp-14"
                :class="{ disabled: filterSP == '' }"
              >
                {{ $t("filter.reset") }}
              </button>
            </b-tab>
            <b-tab title="Produk">
              <div class="input-group">
                <div class="input-group-prepend bg-white">
                  <span class="input-group-text" id="basic-addon1">
                    <div class="font-12">
                      <i class="ri-search-line ri-lg ri-sub"></i>
                    </div>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control search-input"
                  v-model="searchProduk"
                />
              </div>
              <hr class="m-2" />
              <div class="filter-overflow-srch">
                <div
                  class="form-checkbox"
                  v-for="prod in dataProduk"
                  :key="prod.id"
                >
                  <input
                    class="form-checkbox-item"
                    type="checkbox"
                    name="kegiatan-label"
                    :value="prod.id"
                    :id="prod.id"
                    v-model="filterProduk"
                  />
                  <label class="form-checkbox-label ssp-14-400" :for="prod.id">
                    {{ prod.brand }}
                  </label>
                </div>
              </div>
              <button
                @click="resetFilter"
                type="string"
                class="btn btn-success w-100 mt-2 ssp-14"
                :class="{ disabled: filterProduk == '' }"
              >
                {{ $t("filter.reset") }}
              </button>
            </b-tab>
          </b-tabs>
        </b-popover>
      </div>
    </div>
    <div class="table-borderless">
      <b-table-simple hover responsive>
        <b-thead class="bv-head">
          <b-tr>
            <b-th>#</b-th>
            <!-- <b-th><input type="checkbox" @click="selectAll" v-model="allSelected"></b-th> -->
            <b-th>Nama SP</b-th>
            <b-th>Tanggal</b-th>
            <b-th>Produk</b-th>
            <b-th>{{ $t("liquidation.label.rp") }}</b-th>
            <b-th>Qty</b-th>
            <b-th>Value</b-th>
            <!-- <b-th>Retailer 2</b-th>
            <b-th>Retailer 1</b-th> -->
            <b-th>Action</b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="loading">
          <table-spinner cs="8" />
        </b-tbody>
        <!-- <b-tbody> -->
        <b-tbody v-else-if="this.dataLikuidasi.length !== 0">
          <b-tr
            class="ssp-16-400"
            v-for="(data, index) in dataLikuidasi"
            :key="data.id"
          >
            <!-- <b-td><input type="checkbox" :value="data.id" v-model="lqdIds" @click="select"></b-td> -->
            <b-td>{{ ++index }}</b-td>
            <b-td>{{ data.froms.name }}</b-td>
            <b-td>{{ data.day_report | formatDate2 }}</b-td>
            <b-td>{{ data.produknames.brand }}</b-td>
            <b-td>{{ data.price | formatRp }}</b-td>
            <b-td>{{ data.quantity }}</b-td>
            <b-td>{{ data.value | formatRp }}</b-td>
            <!-- <b-td>
              <span v-if="data.retailers2">
                {{ data.retailers2.name }}
              </span>
              <span v-else>
                -
              </span>
            </b-td>
            <b-td>
              <span v-if="data.retailers1">
                {{ data.retailers1.name }}
              </span>
              <span v-else>
                -
              </span>
            </b-td> -->
            <b-td class="action-column">
              <div class="action-wrapper font-12">
                <i
                  class="ri-eye-fill ri-lg ri-mid action-btn"
                  @click="onDetail(data)"
                ></i>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-else class="text-center text-italic">
          <b-tr>
            <b-td colspan="10">{{ $t("global.empty") }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="row align-items-center table-footer">
        <div class="col-sm-6 ssp-14-700">
          {{ $t("global.total_data") }} {{ this.totalData }}
        </div>
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-7 align-self-center page-text">
              {{ $t("global.page_on") }}
            </div>
            <div class="col float-right">
              <div class="row">
                <select class="form-control pagination-pg" v-model="currPage">
                  <option
                    v-for="index in totalPages"
                    :key="index.id"
                    :value="index"
                  >
                    {{ index++ }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col page-arrow ssp-14-700 ml-2">
              <div class="row float-right">
                <div
                  @click="redcPage"
                  class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                >
                  <div class="font-12" :class="{ orange: currPage > 1 }">
                    <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                  </div>
                </div>
                <div
                  @click="addPage"
                  class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                >
                  <div
                    class="font-12"
                    :class="{
                      orange: currPage < totalPages
                    }"
                  >
                    <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-laporan" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">
          <img
            class="mr-2"
            src="@/assets/img/tbhdata-form.png"
            alt="FORM"
            style="height: 1.8rem;"
          />
          {{ modalTitle }}
        </h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form>
          <div class="form-group" v-if="isDetail">
            <label for="nama-produk" class="ssp-16 text-black">
              {{ $t("liquidation.label.from") }}
            </label>
            <input
              type="text"
              class="form-control"
              id="region-laporan"
              v-model="details.froms"
              :disabled="true"
            />
          </div>
          <div class="form-group" v-if="isDetail">
            <label for="nama-produk" class="ssp-16 text-black">{{
              $t("sidebar.region")
            }}</label>
            <input
              type="text"
              class="form-control"
              id="region-laporan"
              v-model="details.region"
              :disabled="true"
            />
          </div>
          <div class="form-group" v-if="isDetail">
            <label for="nama-produk" class="ssp-16 text-black">
              {{ $t("liquidation.label.date") }}
            </label>
            <input
              type="text"
              class="form-control"
              id="nama-produk"
              v-model="details.tanggal"
              :disabled="true"
            />
          </div>
          <div class="form-group">
            <label for="nama-produk" class="ssp-16 text-black">
              {{ $t("liquidation.label.product") }}
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="details.produk"
              :disabled="true"
            />
          </div>
          <div class="form-group">
            <label for="harga-retailer" class="ssp-16 text-black">
              {{ $t("liquidation.label.rp") }}
            </label>
            <input
              type="text"
              class="form-control"
              id="harga-retailer"
              v-model="details.harga_retailer"
              :placeholder="$t('liq_reporting.placeholder.rp')"
              :disabled="true"
            />
          </div>
          <div class="form-group">
            <label for="qty-lkd" class="ssp-16 text-black">
              {{ $t("liquidation.label.qty") }}
            </label>
            <input
              type="text"
              class="form-control"
              id="qty-lkd"
              v-model="details.qty"
              :placeholder="$t('liq_reporting.placeholder.qty')"
              :disabled="true"
            />
          </div>
          <div class="form-group">
            <label for="val-lqd" class="ssp-16 text-black">
              {{ $t("liquidation.label.value") }}
            </label>
            <input
              type="text"
              class="form-control"
              id="val-lqd"
              v-model="details.value_lik"
              placeholder="Harga Retailer x Quantity Likuidasi"
              disabled
            />
          </div>
          <div class="form-group" v-if="details.retailer2 !== null">
            <label class="ssp-16 text-black">
              {{ $t("liquidation.label.r2") }}
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="details.retailer2"
              :disabled="true"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black">
              {{ $t("liquidation.label.r1") }}
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="details.retailer1"
              :disabled="true"
            />
          </div>
          <div class="form-group" v-if="details.distributor !== null">
            <label class="ssp-16 text-black">
              {{ $t("liquidation.label.dist") }}
            </label>
            <input
              v-if="isDetail"
              type="text"
              class="form-control"
              v-model="details.distributor"
              :disabled="true"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black">{{
              $t("liquidation.label.inv")
            }}</label>
            <div class="col-md-10 offset-1 mt-3">
              <img
                v-if="details.invoice != null"
                class="d-block ml-auto mr-auto"
                :src="url + details.invoice"
                :alt="details.invoice"
              />
              <div v-else class="mx-auto text-center">
                {{ $t("feedback.not_avb") }}
              </div>
            </div>
          </div>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import jwtService from "@/core/services/jwt.service";
import { RoleType, filterDateType } from "@/graphql/enum.type";
import { Q_LIST_PRODUK, Q_LIST_USERSEARCH } from "@/graphql/queries";
import { Q_LIST_REPORT } from "@/graphql/tss";
import Axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      isLoading: false,
      loading: false,
      url: jwtService.getURL(),
      dataLikuidasi: [],
      details: {},

      dataSP: [],
      searchSP: "",
      filterSP: [],

      dataProduk: [],
      searchProduk: "",
      filterProduk: [],

      isDetail: true,
      currPage: 1,
      perPage: 10,
      totalPages: null,
      totalData: null,
      filterInput: "",
      filterSelected1: "",
      filterSelected2: "",
      filterDateType: null,

      filterDateTypes: [
        filterDateType.TODAY,
        filterDateType.YESTERDAY,
        filterDateType.THIS_WEEK,
        filterDateType.THIS_MONTH,
        filterDateType.CUSTOM_DATE
      ],
      today: moment(new Date(new Date().setHours(0, 0, 0, 0))).format(
        "YYYY-MM-DD"
      ),
      yesterday: moment(
        new Date(new Date().setHours(0, 0, 0, 0) - 86400000)
      ).format("YYYY-MM-DD"),
      firstDayWeek: moment()
        .startOf("week")
        .toDate(),
      lastDayWeek: moment()
        .endOf("week")
        .toDate(),
      firstDayMonth: null,
      lastDayMonth: null
    };
  },
  apollo: {
    listLaporan: {
      query: () => Q_LIST_REPORT,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          from: this.filterSP,
          to: jwtService.getID(),
          country_id: [],
          region_id: this.getRegionID(),
          prov_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          r1: [],
          r2: [],
          dis: [],
          petani: [],
          produk: this.filterProduk,
          start: this.filterSelected1,
          end: this.filterSelected2,
          dateType: this.filterDateType
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataLikuidasi = data.listLaporan.laporans;
        // console.log(this.dataLikuidasi);
        this.totalPages = data.listLaporan.totalPage;
        this.totalData = data.listLaporan.total;
      },
      error(e) {
        this.catchError(e);
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    },
    listUser: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: this.searchSP,
          role: RoleType.SP,
          country_id: [],
          region_id: [],
          province_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [jwtService.getID()],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      result({ data }) {
        this.dataSP = data.listUser.users;
      }
    },
    listProduk: {
      query: () => Q_LIST_PRODUK,
      variables() {
        return {
          page: 1,
          limit: 99999,
          keyword: this.searchProduk
        };
      },
      result({ data }) {
        this.dataProduk = data.listProduk.produks;
      }
    }
  },
  methods: {
    getDay(value) {
      return moment(value).format("DD");
    },
    resetFilter() {
      this.filterSelected1 = "";
      this.filterSelected2 = "";
      this.filterDateType = null;
      this.filterProduk = [];
      this.filterSP = [];
    },
    resetFilter1() {
      this.filterSelected2 = "";
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    onDetail(data) {
      this.modalTitle = this.$t("liquidation.detail");
      this.isDetail = true;
      this.resetForm();
      this.retailerSelected = "";
      this.details.id = data.id;
      this.details.froms = data.froms.name;
      this.details.tanggal = data.day_report;
      this.details.produk = data.produknames.brand;
      this.details.harga_retailer = data.price;
      this.details.qty = data.quantity;
      this.details.value_lik = data.value;
      if (data.retailers2 !== null) {
        this.details.retailer2 = `${data.retailers2.name} (${data.retailers2.owner})`;
      }
      this.details.retailer1 = `${data.retailers1.name} (${data.retailers1.name})`;
      if (data.distributors !== null) {
        this.details.distributor = `${data.distributors.name} (${data.distributors.name})`;
      }
      this.details.invoice = data.invoice;
      this.details.region = data.regions.region;
      this.$bvModal.show("modal-laporan");
    },
    resetForm() {
      this.details = {};
    },
    async handleExport() {
      this.isLoading = true;
      await Axios.post(
        process.env.VUE_APP_URL_GRAPHQL + "/laporan/excel",
        {
          dateType: this.handleCustomDateExport(this.filterDateType),
          start_date: this.filterSelected1,
          end_date: this.filterSelected2,
          user: this.filterRoleApply,
          region: this.filterRegion,
          product: this.filterProduk
        },
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + jwtService.getToken(),
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `likuidasi-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
  },
  mounted() {
    const dateNow = new Date(new Date().setHours(0, 0, 0, 0));
    this.firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
    this.lastDayMonth = new Date(
      dateNow.getFullYear(),
      dateNow.getMonth() + 1,
      0
    );
  }
};
</script>
