<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("report.sp") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3">
            <h6 class="ssp-24 ml mb-1">{{ $t("report.sp") }}</h6>
          </div>
          <!-- <div class="row ml-3 mb-2">
            <span>
              Ini merupakan Data Laporan Likuidasi dan Laporan Kegiatan <br />
              dari Sales Promotor
            </span>
          </div> -->
          <div class="mx-3 mt-3">
            <b-tabs content-class="mt-3" class="tab-custom">
              <b-tab
                :title="$t('report.liquidation')"
                title-link-class="sp-report-tabs"
                active
              >
                <TabLikuidasi />
              </b-tab>
              <b-tab
                :title="$t('report.activity')"
                title-link-class="sp-report-tabs"
              >
                <TabKegiatan />
              </b-tab>
            </b-tabs>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from "@/components/index";
import TabLikuidasi from "./Likuidasi.vue";
import TabKegiatan from "./Kegiatan.vue";

export default {
  components: {
    Card,
    TabLikuidasi,
    TabKegiatan
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>

<style>
.sp-report-tabs.active {
  color: #000 !important;
  background-color: #f3f3f3 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 2px solid #fd8833 !important;
}

.sp-report-tabs {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #273b4a;
}

.tab-custom div .nav-tabs {
  background-color: #f3f3f3;
  border-radius: 8px;
}
</style>
